import React, { useState, useEffect } from "react";
import "../assets/css/ContentHeader.css";
import Allianz from "../assets/img/customer-img/Allianz.png";
import esc from "../assets/img/customer-img/esc.png";
import alc from "../assets/img/customer-img/alc2.jpg";
import ratp from "../assets/img/customer-img/ratp.png";
import croixRouge from "../assets/img/customer-img/croix_rouge.png";
import factory from "../assets/img/customer-img/factory.webp";

export default function Customer() {
  const images = [
    Allianz,
    esc,
    factory,
    croixRouge,
    ratp,
    alc
  ];

  const [randomizedImages, setRandomizedImages] = useState([]);

  useEffect(() => {
    // Créer une copie de l'array d'images
    const shuffledImages = [...images];

    // Mélanger les images en utilisant l'algorithme de Fisher-Yates
    for (let i = shuffledImages.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledImages[i], shuffledImages[j]] = [shuffledImages[j], shuffledImages[i]];
    }

    // Mettre à jour l'état avec les images mélangées
    setRandomizedImages(shuffledImages);

    // Définir l'intervalle pour remélanger les images toutes les 5 secondes
    const interval = setInterval(() => {
      const shuffledImages = [...images];
      for (let i = shuffledImages.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledImages[i], shuffledImages[j]] = [shuffledImages[j], shuffledImages[i]];
      }
      setRandomizedImages(shuffledImages);
    }, 2000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, []); // Déclenchement initial seulement

  return (
      <div className="flex flex-wrap flex-column">
        <div className="flex flex-wrap justify-content-center">
          <div className="flex flex-row flex-wrap gap-responsive">
            {randomizedImages.map((image, index) => (
              <div key={index} className="flex no-mg mr-8 m-auto">
                <img className="img-customer filter-grey" src={image} alt={`Image ${index}`} />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-8"></div>
      </div>
  );
}
