import React from "react";
import "../assets/css/Footer.css"
import logo from "../assets/img/logo.png";


function Footer() {
  return (
    <>
      <footer style={{bottom:'0'}} class="footer">
        <div class="content-center2">
          <div class="grid p-3 grid-footer align-items-center">
            <div style={{textAlign:'center'}} class="col-4 col-footer">
              <img src={logo}></img>
            </div>
            <div class="col-6 info" style={{ textAlign: "center", fontFamily:'Poppins', color:'white' }}>
              {" "}
              © 2023 SAS COLOR TELECOM – 525 053 617 R.C.S. Nanterre – SIRET :
              52505361700045 – NAF : 7112B – TVA :<br /> FR76525053617 | siège social :
              COLOR TELECOM, 7 Rue du 4 Septembre 92170 Vanves, France
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
