import { useRef, useEffect, useState } from "react";
import "../assets/css/ContentHeader.css";
import check from "../assets/img/check.svg";
import stats from "../assets/img/stats.png";
import headCall from "../assets/img/headCall.png";
import { useTypingEffect } from "./typing/typing-effect";

export default function Header() {
  const emailInputRef = useRef(null);

  // Fonction pour rediriger l'utilisateur vers l'étape 1 après avoir saisi son e-mail
  const redirect = () => {
    const email = emailInputRef.current.value;
    // Vérification de l'email pour s'assurer qu'il ne contient que des caractères alphanumériques et le caractère '@'
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      alert("Veuillez entrer une adresse e-mail valide.");
      return;
    }
    const url = `https://telephonieip.standardenligne.fr/?email=${email}`;
    window.location.href = url;
  };



  return (
    <>
      <div className="flex responsive-width flex-column">
        <div className="flex flex-column align-items-center justify-content-center">
          <div className="flex flex-row c-white p-7 gap-5 stats-img">
            <img src={stats}></img>
            <img src={headCall}></img>
          </div>
          <p className="title-header mt-4 mb-0">La téléphonie tout inclus pour les <br />
            <div style={{ height: '120px' }}>
              <span class="fade-background" id="changing-text">petites entreprises</span>
            </div>
          </p>
        </div>
        <div className="flex justify-content-center">
          <input
            ref={emailInputRef}
            type="email"
            className="input-top responsive-width"
            placeholder="Email professionnel"
          />
          <button
            onClick={redirect}
            className="trialButton btn btn-freeTrail"
          >
            Essai gratuit
          </button>
        </div>
        <div className="flex justify-content-center">
          <div className="flex flex-wrap flex-row gap-4 mt-3 words">
            <div className="flex">
              <img src={check}></img> Sans engagement
            </div>
            <div className="flex">
              <img src={check}></img> Activation instantanée
            </div>
            <div className="flex">
              <img src={check}></img> 100% en ligne
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
