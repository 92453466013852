import React, { useRef } from "react";
import "../assets/css/SendEmailComponent.css";
import { useMediaQuery } from "react-responsive";
import check from "../assets/img/check.svg";
import img_footer from "../assets/img/footer_img.png";


export default function SendEmailComponent() {
  const emailInputRef = useRef(null);

  // Fonction pour rediriger l'utilisateur vers l'étape 1 après avoir saisi son e-mail
  const redirect = () => {
    const email = emailInputRef.current.value;
    // Vérification de l'email pour s'assurer qu'il ne contient que des caractères alphanumériques et le caractère '@'
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      alert("Veuillez entrer une adresse e-mail valide.");
      return;
    }
    const url = `https://telephonieip.standardenligne.fr/?email=${email}`;
    window.location.href = url;
  };


  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className="mg-r-l">
        <div className="grid grid-nogutter">
          <div className="col-5 flex justify-content-end w-auto-responive">
            <img
              className="pic-customer w-full border-left"
              src={img_footer}
              alt="Customer"
            ></img>
          </div>
          <div
            className="col-7 blackBlock flex justify-content-center bg-black border-right"
            style={{
              color: "white",
              padding: "2rem",
            }}
          >
            <div className="flex flex-column text-left gap-5 justify-content-center w-full content-block">
              <div
                className="flex w-full"
                style={{ fontSize: "36px", fontWeight: "600" }}
              >
                Activez le standard
                <br /> téléphonique de votre entreprise
              </div>
              <div className="flex">
                <div className="flex w-full">
                  <div className="flex flex-row w-full">
                    <div className="flex w-full">
                      <input
                        style={{ height: "30px" }}
                        ref={emailInputRef}
                        type="email"
                        className="transparent-input w-full"
                        placeholder="Email professionnel"
                      />
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <button
                    style={{ backgroundColor: '#00BD9D', color: 'white', }}
                    onClick={redirect}
                    className="trialButton btn btn-bottom"
                  >
                    Essai gratuit
                  </button>
                )}
                {/* Afficher btn-bottom-responsive sur les téléphones mobiles */}
              </div>
              {isMobile && (
                <button
                  style={{ backgroundColor: '#00BD9D', color: 'white', }}
                  onClick={redirect}
                  className="trialButton btn btn-bottom-responsive"
                >
                  Essai gratuit
                </button>
              )}
              <div className="flex justify-content-start">
                <div className="bottom-checks flex flex-wrap flex-row gap-3 ml-6">
                  <div className="flex">
                    <img src={check} alt="Check"></img> Sans engagement
                  </div>
                  <div className="flex">
                    <img src={check} alt="Check"></img> Activation instantanée
                  </div>
                  <div className="flex">
                    <img src={check} alt="Check"></img> 100% en ligne
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
