import React from 'react';
import Avis from '../components/Avis';
import '../assets/css/Avis.css';

export default function AvisComponent() {
  return (
    <div style={{ maxWidth: '100%' }}>
      <p className='p-title' style={{ fontSize: '50px' }}>Nos clients en parlent le mieux</p>
      <div className="glimmer-orange"></div>
      <div style={{ zIndex: '1' }}>
        <div className='flex flex-wrap flex-row justify-content-center gap-8'>
          <div className="col-md-6">
            <Avis texte={`Je recommande cette société. J’ai basculé ensemble de mes agences chez Standard en Ligne je suis vraiment satisfaite et mon équipe aussi. Les appels sont en cascades et redirection possible d’une agence à une agence et/ou via des applications. Interface simple et intuitive, je peux gérer ma configuration de mon standard en toute autonomie.
Merci pour votre suivi`} auteur={"Audrey Alexandre, Agent Général ALLIANZ"} />
          </div>
          <div className="col-md-6">
            <Avis texte={`Je suis client chez eux depuis plusieurs années et à chaque fois que j’ai eu besoin d’eux, ils ont toujours répondu présent. La nouvelle interface est vraiment top, pratique et parfaitement adaptée aux mobiles. Je n’ai eu aucun problème technique depuis que j’ai migré ma ligne fixe chez eux. Je ne peux que recommander à 100% leur solution. Encore merci !`} auteur={'Thibault Zoete, Omnifunnel'} />
          </div>
          <div className="col-md-6">
            <Avis texte={`Nous recommandons vivement standard en ligne,
après avoir essayer plusieurs opérateurs c’est le seul qui a su tenir ses engagements.
Notre activité est dépendante d’un standard opérationnels de multiple appels en simultané, une équipe commerciale a l’écoute et des conseillé professionnelle pour le moment tout se passe bien!`} auteur={'BNSR AUTOS'} />
          </div>
        </div>
        <div className='avis-responsive flex flex-wrap flex-row justify-content-center mt-6 gap-8'>
          <div className="glimmer-blue"></div>
          <div className="col-md-6">
            <Avis texte={`Je conseille Standard en Ligne car cela a vraiment rajouté un élément professionnel à mon entreprise. Les équipes m’ont bien accompagnées pour les débuts et le système est intuitif et fonctionne très bien. L’interface est facile d’utilisation, on peut se gérer facilement et en entière autonomie.
Je recommande !`} auteur={'Veronika ZIZKA, VZ Agence'} />
          </div>
          <div className="col-md-6">
            <Avis texte={`Inscription rapide et facile. Service technique aux petits soins pour savoir si on a pas de problème. La solution est simple à prendre en main et plus intuitive que les autres solutions que j’ai pu tester. Au top je recommande !
`} auteur={'ABC Peinture'} />
          </div>
          <div className="col-md-6">
            <Avis texte={`Merci à l’équipe de STANDARD EN LIGNE pour son efficacité, sa rapidité et surtout, son abonnement sans engagement pour les professionnels.
Nous recommandons vivement car nous avons divisé par deux, nos frais téléphoniques.
Plus de perte d’appels et conseil technique performant.`} auteur={'Romuald Richar, KROMYK'} />
          </div>
        </div>
      </div>
    </div>
  );
}
