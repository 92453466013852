import React from "react";
import "../assets/css/FreeTrialButton.css"


export default function Header(){
    return(
        <>
        <a href="https://commande.standardenligne.fr/"><p className="trialButton">Essai gratuit</p></a>
        </>
    )
}