import { useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import '../assets/css/Faq.css'

export default function ToggleableDemo() {
  const ref = useRef(null);
  return (
    <>
      <div className='faq-content'>
        <p style={{ fontSize: '50px', fontWeight: '700' }}> Foire aux questions</p>
        <div className='flex flex-column mr-8 ml-8'>
          <div>
            <Divider />
            <Panel className='panel' collapsed ref={ref} header="Qu’est-ce Standard en ligne ?" toggleable>
              <p className="m-0">
                Standard en ligne est la solution idéale pour les petites et moyennes entreprises qui souhaitent bénéficier d’un Standard Téléphonique facile à utiliser pour pouvoir gérer efficacement ses appels entrants.
                Il suffit de quelques clics pour choisir un numéro de téléphone, configurer son Standard Téléphonique (Pré-décroché, renvoi d’appels), et commencer recevoir ses premiers appels clients depuis son téléphone mobile, application mobile ou ordinateur, sans aucune connaissance technique.
                Standard en ligne vous permet de suivre précisément les appels reçus sur votre standard grâce au journal d’appel interactif, l’intégration CRM , les enregistrements d’appels ainsi que les notifications par e-mail d’appels
              </p>
            </Panel>
            <Divider />
          </div>
          <div>
            <Panel collapsed ref={ref} header="Comment utiliser Standard en ligne ?" toggleable>
              <p className="m-0">
                Pour utiliser Standard en ligne, rien de plus simple: Il vous suffit d’avoir une ligne fixe ou mobile pour recevoir vos appels sur vos téléphone existants, ou d’un smartphone et un ordinateur pour émettre et recevoir vos appels téléphoniques en illimité.
              </p>
            </Panel>
            <Divider />
          </div>
          <div>
            <Panel collapsed ref={ref} header="Combien coûte Standard en ligne ?" toggleable>
              <p className="m-0">
                Standard en ligne est une solution 100% sans engagement et sans frais de mise en service. Standard en ligne propose plusieurs forfaits adaptés à votre besoin et à votre consommation
              </p>
            </Panel>
            <Divider />
          </div>
          <div>
            <Panel collapsed ref={ref} header="Puis-je arrêter l’abonnement quand je le souhaite ?" toggleable>
              <p className="m-0">
                Standard en ligne est un opérateur 100% sans engagement: Vous pouvez ainsi résilier facilement à tout moment sans préavis en envoyant un simple e-mail.
              </p>
            </Panel>
            <Divider />
          </div>
          <div>
            <Panel collapsed ref={ref} header="Est-ce que les appels sont inclus dans les offres ?" toggleable>
              <p className="m-0">
              Oui, des forfaits d’appels sont inclus dans chacune de nos offres. Pour les offres de Standard Virtuel, vous disposez de 1h, 6h, 10h ou 30h de redirection d’appels vers vos lignes fixes et mobiles existantes en France Métropolitaine, Corse et +50 destinations internationales. Pour les offres VOIP, vous bénéficiez d’appels entrants illimités et d’appels sortants inclus (voir conditions) vers les Fixes et Mobiles en France Métropolitaine et Corse avec le forfait Start, et d’appels entrants illimités et sortants inclus (voir conditions) vers +50 destinations internationales Fixes et Mobiles avec le forfait Pro.
              </p>
            </Panel>
            <Divider />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#0D081C' }} className="flex flex-row text-left justify-content-between align-items-center mt-8">
        <div className="flex flex-column c-white">
          <p style={{ fontSize: '25px' }} className='ml-6 mb-0'>Vous avez encore des questions ?</p>
          <p className='ml-6'>Vous ne trouvez pas la réponse que vous cherchez. Veuillez discuter<br /> avec notre équipe amicale.</p>
        </div>
        <button
          style={{ backgroundColor: '#00BD9D', color: 'white', height: "50px" }}
          className="trialButton btn mr-6"
        >
          Appeler au 01 87 200 200
        </button>
      </div>

    </>
  )
}