import React from "react";
import "../assets/css/Options.css";
import img_number from "../assets/img/options/numbers.png";
import calls from "../assets/img/options/calls.png";
import config from "../assets/img/options/config.png";

export default function Options() {


  return (
    <div className="">
      <p className="options-title">Votre solution de téléphonie VOIP<br/> en quelques clics</p>
      <div className="flex flex-wrap flex-row gap-8 text-left justify-content-center">
        <div className="flex flex-column peek-number">
          <div className="div-width">
            <div className="div-options">
              <img className="img-options number-peek-size w-full" src={img_number}></img>
            </div>
            <p className="m-0" style={{ fontSize: "30px", fontWeight: "600" }}>Choisissez un numéro facilement mémorisable</p>
            <p className="m-0">Sélectionnez un numéro parmi plus de 100000 numéros disponibles ou utilisez un numéro que vous avez déjà.</p>
          </div>
        </div>
        <div className="flex flex-column">
          <div className="div-width">
            <div className="div-options config-div">
              <img className="" src={config}></img>
              <p className="m-0" style={{ fontSize: "30px", fontWeight: "600" }}>Configurez facilement votre standard</p>
              <p className="m-0">Réglez le standard téléphonique de votre entreprise simplement : Activez votre message d’accueil, paramétrez vos horaires, renvoyez les appels vers vos collaborateurs, etc.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-column">
          <div className="div-width calls">
            <div className="">
              <img className="customer-call" src={calls}></img>
              <div className="div-text">
                <p className="m-0" style={{ fontSize: "30px", fontWeight: "600" }}>Recevez et passez des appels depuis votre mobile et ordinateur</p>
                <p className="m-0">Appelez vers les Fixes et Mobiles en France Métropolitaine, dans les DOM et vers l’international (selon offre souscrite)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
