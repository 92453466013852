import React from "react";
import logo from "../assets/img/logo.png";
import FreeTrialButton from "../components/FreeTrailButton";
import "../assets/css/Header.css";
import ContentHeader from "./ContentHeader";
import Customer from "./Customers";

export default function Header() {
  return (
    <>
      <div className="body-header">
        <div className="flex flex-row">
          <div className="flex">
            <img style={{ height: '40px' }} src={logo} className="logo" alt="Logo"></img>
          </div>
          <div className="mobile-center flex ml-auto mr-7 items-center">
            <FreeTrialButton />
          </div>
        </div>
        <div
          className="flex align-items-center justify-content-center"
        >
          <div className="glimmer-orange-top"></div>
          <div className="glimmer-blue-top"></div>
          <ContentHeader />
        </div>
        <div className="mt-8">
          <Customer />
        </div>
      </div>
    </>
  );
}
