import React from 'react'
import Header from '../components/Header'
import GroupBlocks from "../components/GroupBlocks";
import Options from "../components/Options";
import AvisResponsive from "../components/AvisResponsive";
import AvisComponent from "../components/AvisComponent";
import SendMailComponent from "../components/SendEmailComponent";
import Faq from "../components/Faq";
import Footer from '../components/Footer';
import '../assets/css/Home.css'
import { useMediaQuery } from "react-responsive";



export default function Home() {
  const isMobile = useMediaQuery({ maxWidth: 890 });
  const isMobileAvis = useMediaQuery({ maxWidth: 1610 });

  return (
    <div className='App'>
      <main>
        <div className="glimmer-blue"></div>
        <Header />
        <Options />
        <div style={{ marginTop: "6rem" }}>
          <GroupBlocks />
        </div>
        {isMobileAvis ? <AvisResponsive /> : <AvisComponent />}
        
        <div style={{ marginTop: "6rem" }}>
          {isMobile ? '' : <Faq />}
        </div>
        <div style={{ marginTop: "6rem" }}>
          <SendMailComponent />
        </div>
      </main>
      <Footer />
    </div>
  )
}
