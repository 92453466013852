import React from "react";
import "../assets/css/GroupBlocks.css";
import appels from "../assets/img/groupBlocks/appels.png";
import home from "../assets/img/groupBlocks/home.png";
import customer from "../assets/img/groupBlocks/customer.png";
import integration from "../assets/img/groupBlocks/integration.png";
import app from "../assets/img/groupBlocks/app_mobile.png";

export default function GroupBlocks() {
  const scrollToBottom = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const scrollTo = fullHeight - windowHeight;

    window.scrollTo({
      top: scrollTo,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="bg-black">
        <div className="flex justify-content-center">
          <p
            className="tel-cloud"
            style={{
              fontSize: "3vw",
              fontWeight: "600",
              color: "white",
              textAlign: "center",
            }}
          >
            Toutes les fonctionnalités incluses pour une
            <br /> <span className="fade-background">Téléphonie VOIP</span> sur
            mesure
          </p>
        </div>
        <div>
          <div className="flex flex-column flex-wrap align-items-center text-left">
            <div className="flex">
              <div className="flex flex-row justify-content-center flex-wrap gap-4">
                <div className="flex responsive-layout">
                  <div className="accueil p-5 flex flex-column grey-bg justify-content-between">
                    <div className="flex">
                      <img src={home}></img>
                    </div>
                    <div className="flex">
                      <p className="font-25 mb-0" style={{ color: "white" }}>
                      Standard Téléphonique<br/> professionnel
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex p-5 flex-column grey-bg">
                  <img src={customer}></img>
                  <p className="font-25 mb-0">Appels inclus vers<br/> Fixes et Mobiles</p>
                </div>
              </div>
            </div>
            <div className="flex" style={{ marginTop: "2rem" }}>
              <div className="grey-bg no-p softphone">
                <div className="flex flex-wrap flex-row align-items-center justify-content-center gap-6">
                  <div className="flex flex-column">
                    <p className="font-25 mb-0">Application Mobile et<br/> Ordinateur</p>
                    <p className="c-white">Passez vos appels en télétravail ou en mobilité grâce<br/> aux applications softphone inclus<br/> dans votre abonnement</p>
                  </div>
                  <img src={app}></img>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-content-center flex-row mt-4 gap-5">
              <div className="flex flex-column grey-bg no-p">
                <img className="mr-6 ml-6" src={integration}></img>
                <p className="font-25 mb-0 mt-0">+ 20 intégrations CRM</p>
              </div>
              <div className="flex flex-column grey-bg ">
                <img src={appels}></img>
                <p className="font-25 mb-0 p-0">Suivez vos appels</p>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: "3rem", height: "9rem" }}
            className="flex justify-content-center"
          >
            <button
              style={{ height: "50px", backgroundColor:'#00BD9D', color:'white' }}
              onClick={scrollToBottom}
              className="trialButton btn"
            >
              Essayer gratuitement
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
