import React from 'react'
import star from '../assets/img/Star.png'
import line from '../assets/img/line.svg'
import '../assets/css/Avis.css'

export default function Avis({ texte, auteur }) {
  return (
    <div className='content-center'>
      <div className="flex flex-column text-left">
        <div className="flex flex-row justify-content-center">
          <div className="flex flex-column avis">
            <img className='star' src={star} alt="Star"></img>
            <p className='content-avis'>{texte}</p>
            <div className="flex flex-row gap-5">
              <div className="flex flex-column">
                <img src={line} alt="Line"></img>
                <p className='m-0 mt-2 mb-0'>{auteur}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
